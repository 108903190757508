<template>
    <div :class="className" :style="{height:height,width:width}" />
  </template>
  <script>
  import * as echarts from 'echarts'
  import "echarts-gl";
  import { debounce } from '@/utils'
  import { markRaw } from 'vue'
  export default {
    props: {
      className: {
        type: String,
        default: 'chart'
      },
      width: {
        type: String,
        default: '100%'
      },
      height: {
        type: String,
        default: '100%'
      },
      barData: {
        type: Object,
        default: ()=>{
          return {}
        }
      },
      barxAxis: {
        type: Array,
        default: ()=>{
          return []
        }
      },
      units:{
        type:String
      },
    },

    data() {
      return {
        chart:null
      }
    },
    watch:{
      'barxAxis':{
        handler() {
           this.refreshData()
        },
        deep:true,
      },
      'barData':{
        handler() {
           this.refreshData()
        },
        deep:true,
      }
    },
    mounted() {
      this.refreshData()
    //   this.__resizeHandler = debounce(() => {
    //     if (this.chart) {
    //       this.chart.resize()
    //     }
    //   }, 500)
    //   window.addEventListener('resize', this.__resizeHandler)
    },
    beforeUnmount() {
      if (!this.chart) {
        return
      }
      window.removeEventListener('resize', this.__resizeHandler)
      this.chart.dispose()
      this.chart = null
    },
    methods: {
      initChart() {
          this.chart = markRaw(echarts.init(this.$el))
          this.setOption()
      },
      setOption(){
          let _this = this
          this.chart.setOption({
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                }
            },
            legend: {
                textStyle:{
                    color:'#fff'
                }
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'value',
                axisLabel:{
                    color:'#fff'
                },
                splitLine:{
                  show:false  
                },
                
                
            },
            color:['#00B286','#FFF32F','#E48C02','#F80000'],
            yAxis: {
                type: 'category',
                data: this.barxAxis,
                axisLabel:{
                    color:'#fff'
                }, 
                axisLine:{
                  show:false    
                }           
            },
            series: [
                {
                name: '优',
                type: 'bar',
                stack: 'total',
                barWidth:30,
                data: this.barData.excellent
                },
                {
                name: '良',
                type: 'bar',
                stack: 'total',
                barWidth:30,
                data: this.barData.good
                },
                {
                name: '中',
                type: 'bar',
                stack: 'total',
                barWidth:30,
                data: this.barData.average
                },
                {
                name: '差',
                type: 'bar',
                stack: 'total',
                barWidth:30,
                data: this.barData.poor
                },
            ]
          })    
      },
      refreshData(){
          //刷新数据
          if(this.chart){
            this.setOption();  
          }else{
            this.initChart()
          }  
      }
    }
  }
  
  </script>